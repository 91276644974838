import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ lang, title, description, slug, meta, image, children }) {
  const { sanity } = useStaticQuery(graphql`
    query {
      sanity: sanitySiteSettings {
        title
        description
        url
        twitter
        image: mainImage {
          alt
          asset {
            fixed(width: 300) {
              src
              width
              height
            }
          }
        }
      }
    }
  `);

  const metaTitle = title || sanity.title;
  const metaImage = image || sanity.image;

  const metaDescription = description || sanity.description;
  const canonical = slug ? `${sanity.url}${slug}` : sanity.url;
  console.log(canonical);
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={`${sanity.title}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical
              }
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:url`,
          content: canonical
        },
        {
          name: `twitter:site`,
          content: sanity.twitter ? sanity.twitter : null
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: metaImage.asset.fixed.src
                },
                {
                  property: "og:image:width",
                  content: metaImage.asset.fixed.width
                },
                {
                  property: "og:image:height",
                  content: metaImage.asset.fixed.height
                },
                {
                  property: "og:image:alt",
                  content: metaImage.alt
                },
                {
                  name: "twitter:card",
                  content: "summary"
                }
              ]
            : []
        )
        .concat(meta)}
    />
  );
}
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  slug: ``
};
SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    asset: PropTypes.shape({
      fixed: PropTypes.shape({
        src: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired
      })
    })
  })
};
export default SEO;
